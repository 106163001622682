// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Thumbs, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Thumbs, Autoplay]);

new Swiper("#mainSlider", {
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  // autoplay: {
  //   delay: 2000,
  // },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

new Swiper("#ourTeam", {
  slidesPerView: 3,
  spaceBetween: 20,
  loop: true,
  // autoplay: {
  //   delay: 2000,
  // },
  centeredSlides: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
});

new Swiper("#availablePositions", {
  slidesPerView: 1,
  spaceBetween: 20,
  // autoplay: {
  //   delay: 2000,
  // },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: (index, className) => {
      return '<h3 class="' + className + '">' + (index + 1) + "</h3>";
    },
  },

  navigation: {
    nextEl: ".swiper-available-next",
    prevEl: ".swiper-available-prev",
  },
});
