import "intersection-observer";
import "./sliders.js";
import sal from "sal.js";

// loadClass
const theApp = document.getElementById("theApp");
theApp.classList.add("is-loaded");

// LazyLoad Images
window.addEventListener("load", (event) => {
  // Observer
  const inViewElms = document.querySelectorAll(".inViewJs");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(
      (entry) => {
        const lazyImage = entry.target.querySelector("img[data-src]");
        if (entry.intersectionRatio > 0) {
          // console.log(lazyImage.querySelector("img[data-src]"));
          lazyImage.src = lazyImage.dataset.src;

          lazyImage.onload = function () {
            entry.target.classList.add("inViewJs-active");
            observer.unobserve(entry.target);
          };
        } else {
          // console.log("out of view");
          // entry.target.classList.remove("inViewJs-active");
        }
      },
      { rootMargin: "0px 0px 100px 0px" }
    );
  });

  inViewElms.forEach((elm) => {
    observer.observe(elm);
  });
});

// searchOverlay
let searchButton = document.getElementById("searchButton"),
  searchOverlay = document.getElementById("searchOverlay"),
  searchOverlayBGClose = document.querySelector(
    ".searchOverlay .searchOverlay-bg"
  ),
  searchOverlayClose = document.querySelector(
    ".searchOverlay .searchOverlay-close"
  );
if (searchButton) {
  searchButton.addEventListener("click", () => {
    searchOverlay.classList.add("is-active");
  });
  searchOverlayBGClose.addEventListener("click", () => {
    searchOverlay.classList.remove("is-active");
  });
  searchOverlayClose.addEventListener("click", () => {
    searchOverlay.classList.remove("is-active");
  });
}

// OVERLAY MENU
let closeMenu = document.getElementById("closeMenu"),
  menuOverlay = document.querySelector(".menuOverlay"),
  menuBgOverlay = document.querySelector(".menuOverlay .menuOverlay-menuBG"),
  menuCloseOverlay = document.querySelector(
    ".menuOverlay .menuOverlay-close p"
  );
if (closeMenu) {
  closeMenu.addEventListener("click", () => {
    menuOverlay.classList.add("is-active");
  });
  menuBgOverlay.addEventListener("click", () => {
    menuOverlay.classList.remove("is-active");
  });
  menuCloseOverlay.addEventListener("click", () => {
    menuOverlay.classList.remove("is-active");
  });
}

// // DRAGGABLE SECTION
// let dragContainer = document.getElementById("dragLabel");
// const handlerFunction = (e) => {
//   console.log(e);
// };
// if (dragContainer) {
//   dragContainer.addEventListener("mousedown", () => {
//     dragContainer.addClass;
//   });
//   dragContainer.addEventListener("dragenter", handlerFunction, false);
//   dragContainer.addEventListener("dragleave", handlerFunction, false);
//   dragContainer.addEventListener("dragover", handlerFunction, false);
//   dragContainer.addEventListener("drop", handlerFunction, false);
// }
sal();
